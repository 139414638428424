<template>
  <v-row v-scroll="onScroll">
   <!-- header -->
   <mini-header :key="resetTab" :list="statusTab" :filter.sync="filter" :status="status" title="Documentos recibidos" :breadcrumbs="breadcrumbs" :hiddenTab="false" :scroll="scroll">
     <template v-slot:secondary>
      <VMainColumnRecordVisualization :headers.sync="headersAux" :pageSize.sync="pageSize" object="purchases-pending" />
       <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" transition="slide-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-2" outlined v-bind="attrs" v-on="on">
            <v-icon left>mdi-plus-box </v-icon>Opciones<v-icon size="18" right>mdi-chevron-down</v-icon>
          </v-btn>
        </template>
        <v-list class="pa-0">
          <v-list-item class="px-3" @click="dialogExport=true" :disabled="count === 0" :ripple="false">
            <v-list-item-title class="body-2">Descargar documentos</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
       <v-query-builder class="ml-2" :query-map="filters" model="purchases" :active.sync="activeFilter" />
     </template>
   </mini-header>
   <!-- end header -->

   <v-col cols="12" class="px-0 mt-25">
    <purchase-list
      @activeBtnFilter="activeFilter = true"
      :showPermissionDenied="isPermissionDenied"
      :selectedDocuments.sync="selectedDocuments"
      :headerList="headerList"
      :pageSize="pageSize"
      :loading="loading"
    />
   </v-col>

   <!-- dialog export -->
   <v-dialog v-model="dialogExport" width="700" persistent no-click-animation overlay-color="grey-500">
     <v-card>
       <v-card-title class="pa-0">
         <v-toolbar class="transparent" flat height="46">
          <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-semibold">Descarga de documentos pendientes</v-toolbar-title>
           <v-spacer />
           <v-toolbar-items>
             <v-row align="center" justify="center" class="mr-3">
               <v-btn @click="dialogExport=false" icon small retain-focus-on-click><v-icon color="grey-300--text" size="20">mdi-close</v-icon></v-btn>
             </v-row>
           </v-toolbar-items>
         </v-toolbar>
       </v-card-title>
       <v-divider />
       <v-card-text class="px-5 py-2">
          <v-list class="pa-0 transparent">
            <!-- <v-list-item>
              <v-list-item-content class="pb-0">
                <v-list-item-title class="grey-700--text body-1 font-weight-bold">Resumen de la exportación</v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
            <v-list-item class="mt-2">
              <v-list-item-content class="pt-0">
                <v-list-item-title class="grey-700--text body-1 font-weight-bold">Selecciona el formato de los archivos:</v-list-item-title>
                <v-list-item-subtitle class="pt-2">
                  <v-row class="ml-n1" no-gutters>
                    <v-col cols="6" class="pa-0" v-for="file in fileFormat" :key="file">
                      <v-checkbox class="ma-0" @click="getFileFormat(file)" :input-value="types.includes(file) || false" column :label="file" :disabled="['XLSX', 'CSV'].includes(file) && count >= 1000000" dense hide-details />
                    </v-col>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <!-- alert -->
          <v-alert class="inf-alert-blue blue-500--text rounded-md mt-2" :height="58" dense text>
            <div class="d-flex pa-0 my-1">
              <v-icon color="blue-500" size="22">mdi-information-outline</v-icon>
              <div class="ml-4">
                <span class="caption" style="line-height: 14px;">Esta acción
                  <span class="font-weight-bold">podría tardar varios minutos</span> en completarse. Recibirás un
                  <span class="font-weight-bold"> correo electrónico con el enlace de descarga.</span>
                </span>
              </div>
            </div>
          </v-alert>
          <!-- end alert -->
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn outlined @click="dialogExport=false" :ripple="false">Cancelar</v-btn>
          <v-btn color="blue-500" @click="createExport" :ripple="false" :disabled="!types.length">Descargar</v-btn>
        </v-card-actions>
     </v-card>
   </v-dialog>
   <!-- end dialog export -->
 </v-row>
</template>
<script>
import { mapState } from 'vuex'
import { groupBy, isEqual } from 'lodash'
import MiniHeader from '@/components/commons/MiniHeader'
import PurchaseList from '@/modules/purchase/pending-receive/components/PendingReceiveList'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'
import ListViewMixin from '@/mixins/ListViewMixin'
import DocumentTypes from '@/collections/documentTypes'
import PurchaseStatuses from '@/collections/purchaseStatuses'
import VMainColumnRecordVisualization from '@/components/commons/VMainColumnRecordVisualization'

export default {
 components: {
   PurchaseList,
   VQueryBuilder,
   MiniHeader,
   VMainColumnRecordVisualization
 },
 mixins: [
   ListViewMixin
 ],
  data: () => ({
  loading: false,
  reset: 0,
  resetTab: 0,
  errorXML: null,
  errorCSV: null,
  pageSize: null,
  headerList: [],
  activeFilter: false,
   scroll: 0,
   filter: 'Todos',
   status: [
      {
       title: 'Sin respuesta',
       icon: 'mdi-clock'
     },
     {
       title: 'Reclamado',
       icon: 'mdi-close'
     },
      {
       title: 'Aceptado',
       icon: 'mdi-check'
     }
   ],
   isPermissionDenied: false,
   dialogExport: false,
   types: [],
   documentTypes: DocumentTypes,
   purchaseStatuses: PurchaseStatuses.slice(0, 7),
   filters: [
     {
       type: 'text',
       id: 'supplier__name',
       label: 'Proveedor',
       value: null,
       category: 'a',
       operators: ['contiene']
     },
     {
       type: 'text',
       id: 'supplier__tax_id',
       label: 'RUT',
       value: null,
       category: 'a',
       operators: []
     },
     {
       type: 'date',
       id: 'Fecha del documento',
       label: 'Fecha doc.',
       value: null,
       value2: null,
       category: 'b',
       operator: null,
       operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
     },
     {
       type: 'date',
       id: 'date_recep_sii',
       label: 'Fecha recep. SII',
       value: null,
       value2: null,
       category: 'b',
       operator: null,
       operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
     },
     {
       type: 'select',
       isMultiple: true,
       id: 'document_type__in',
       value: null,
       label: 'Tipo de documento',
       category: 'c',
       choices: []
     },
     {
       type: 'numeric',
       id: 'number',
       label: 'Número de folio',
       value: null,
       value2: null,
       category: 'c',
       operator: null,
       operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
     },
     {
       type: 'numeric',
       id: 'total',
       label: 'Total',
       value: null,
       value2: null,
       category: 'c',
       operator: null,
       operators: ['es igual', 'es menor a', 'es mayor a', 'rango']
     },
      {
       type: 'select',
       isMultiple: false,
       id: 'status__in',
       value: null,
       label: 'Estados',
       category: 'd',
       choices: []
     }
   ],
   breadcrumbs: {
     main: 'Recepción',
     children: [
       {
         text: 'Pendientes por recibir'
       }
     ]
   },
   fileFormat: ['XLSX', 'CSV'],
   selectedDocuments: [],
   headers: [
      { text: 'Proveedor', value: 'supplier', sortable: true, show: true, disabled: false, label: 'Proveedor', fixed: true },
      { text: 'Tipo de documento', value: 'document_type', sortable: true, show: true, disabled: false, label: 'Tipo de documento', fixed: true },
      { text: 'Folio #', value: 'number', align: 'end', sortable: true, show: true, disabled: false, label: 'Folio #', fixed: true },
      { text: 'Fecha doc.', value: 'date', align: 'end', sortable: true, show: true, disabled: false, label: 'Fecha', fixed: true },
      { text: 'Sincronizado el', value: 'created', align: 'end', sortable: true, show: false, disabled: false, label: 'Sincronizado el', fixed: false },
      { text: 'Fecha recep. SII', value: 'date_recep_sii', align: 'end', sortable: true, show: false, disabled: false, label: 'Fecha recep. SII', fixed: false },
      { text: 'Estado', value: 'status', width: 130, show: true, disabled: false, label: 'Estado', fixed: true },
      { text: 'Total', value: 'total', align: 'end', sortable: true, width: 135, show: true, disabled: false, label: 'Total', fixed: true },
      { text: '', value: 'actions', align: 'end', show: true, width: 16 }
    ],
    headersAux: []
 }),
 computed: {
   ...mapState({
     count: state => state.purchases.purchases_from_siiList.length
   }),
   statusTab () {
     return Object.entries(groupBy(this.purchaseStatuses, 'summary')).map(([key, value]) => {
       return { key, count: value.length, children: value }
     })
   }
 },
  watch: {
    filter: {
      handler (val) {
        let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
        if (val > 0) {
          delete currentQuery.page
          let query = this.purchaseStatuses.filter((item) => item.summary === this.status[val - 1]?.title).map((item) => item.id).toString()
          this.$router.replace({ name: this.$route.name, query: { ...currentQuery, status__in: query } }).catch(() => { })
        } else {
          delete currentQuery.page
          this.$router.replace({ name: this.$route.name, query: currentQuery}).catch(() => { })
          this.resetTab += 1
        }
      },
        inmediate: false
      },
   headersAux: {
     handler (val) {
        this.headerList = val
      },
      inmediate: true
   },
   $route () {
     if (!this.$route.query?.status__in) {
        this.filter = 0
        this.resetTab += 1
     } else this.setTab()
    }
  },
  created () {
  if ((typeof this.getList === 'function' && Object.keys(this.$route?.query).length)) {
    this.getList()
  }
  if (this.$route.query.supplier__group) {
      this.breadcrumbs.children.push({
        text1: this.$route.query.name,
        route1: { name: 'SuppliersGroupsRetrieveUpdate', params: { id: this.$route.query.supplier__group } }
      })
    } else if (this.$route.query.name) {
    this.breadcrumbs.children.push({
      text1: this.$route.query.name,
      route1: { name: 'SupplierRetrieveUpdate', params: { id: this.$route.query.name } }
    })
  }
  if ((typeof this.getList === 'function' && Object.keys(this.$route?.query).length)) {
    this.getList()
  }
  this.setFilterDocumentType()
  this.setFilterStatusType()
  this.setTab()
  this.headersAux = [...this.headers]
 },
  methods: {
    setTab () {
      if (this.$route.query.status__in) {
      this.resetTab += 1
      this.statusTab.forEach((item) => {
        const statusList = item.children.map((child) => child.id?.toString())
        const query = this.$route.query.status__in.split(',')
        if (isEqual(statusList, query)) {
          const index = this.status.findIndex(({title}) => title === item.key)
          this.filter = index !== -1 ? index + 1 : 0
        }
      })
    }
  },
  getFileFormat (val = null) {
    if (!this.types?.find((s) => s === val)) this.types.push(val)
    else {
      this.types.splice(this.types.indexOf(val), 1)
      this.types = [...this.types]
    }
  },
   onScroll (e) {
     this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
   },
   setFilterDocumentType () {
     const currentDocumentType = this.documentTypes.filter(({country}) => country === this.$store.getters['base/currentCountry'])
     if (currentDocumentType.length) {
       const index = this.filters.findIndex(({id}) => id === 'document_type__in')
       this.filters[index].choices = currentDocumentType.map(({name, id}) => {
         return {
           label: name,
           value: id
         }
       })
     }
   },
   setFilterStatusType () {
    const index = this.filters.findIndex(({id}) => id === 'status__in')
     this.filters[index].choices = this.statusTab.map((item) => {
      return {
        label: item.key,
        value: item.children.map(({id}) => id).toString()
      }
    })
   },
   getList () {
     if (this.$helpers.hasPermission(['view_purchase'])) {
      this.loading = true
       this.$store.dispatch('purchases/LIST', {
         resource: 'purchases_from_sii',
         query: {
           ...this.$route.query,
           ...(!this.$route.query?.ordering && { ordering: '-date,-created'}),
           expand: 'supplier'
         }
       })
      .finally(() => {
          this.reset += 1
          this.loading = false
        })
     } else {
       this.isPermissionDenied = true
     }
   },
   str2bytes (str) {
     var bytes = new Uint8Array(str.length)
     for (var i = 0; i < str.length; i++) {
       bytes[i] = str.charCodeAt(i)
     }
     return bytes
   },
    async createExport () {
      this.errorCSV = null
      this.errorXML = null
      this.dialogExport = false
      let array = []
      let currentQuery = this.deleteQueries()
      if (this.types.includes('CSV') && this.types.includes('XLSX')) {
        array = this.types.filter(item => item !== 'CSV' && item !== 'XLSX')
        array.push('XLSX, CSV')
      } else array = this.types
      if (array.includes('XLSX, CSV') || array.find((item) => ['XLSX', 'CSV'].includes(item))) {
        await this.exportCSVandXLSX(currentQuery)
      }
      if (array.includes('XML') || this.$store?.state?.auth?.account?.country === 'PE') {
        this.$store.commit('global/SET_API_SERVICES', false)
        this.$store.dispatch('accounts/LIST', {
          resource: 'exports/purchases',
          query: {
            ...currentQuery,
            extension: this.$store?.state?.auth?.account?.country === 'PE' ? this.types.toString() : 'XML',
            ...(((['perPage'].includes(this.toggleDropdown) || (this.selectedDocuments.length > 0 && this.selectedDocuments.length <= 20)) && !['all', 'filtered'].includes(this.toggleDropdown)) && { id__in: this.selectedDocuments.map(({id}) => id).toString()})
          },
          loader: false
        })
        .catch((error) => {
          this.errorXML = this.parseErrors(error.response.data)
        })
        .finally(() => {
          this.types = []
        })
      }
      if (this.errorXML || this.errorCSV) this.$dialog.message.error(`${this.errorXML ? `${this.errorXML} \n` : ''} ${this.errorCSV ? `${this.errorCSV} \n` : ''}}`)
      else this.$dialog.message.info('Se ha iniciado el proceso de descarga y se le notificará via email, una vez que haya finalizado', { timeout: 10000 })
    },
    exportCSVandXLSX (currentQuery = {}) {
      this.$store.commit('global/SET_API_SERVICES', true)
      const query = {
        ...currentQuery,
        ...(((['perPage'].includes(this.toggleDropdown) || (this.selectedDocuments.length > 0 && this.selectedDocuments.length <= 20)) && !['all', 'filtered'].includes(this.toggleDropdown)) && { id__in: this.selectedDocuments.map(({id}) => id).toString()})
      }

      const { formatParams, formatParamsTypeList } = this.setFormatParams(query)
      const formatQuery = formatParams && formatParamsTypeList ? `${formatParams}&${formatParamsTypeList}` : `${!formatParams ? `${formatParamsTypeList}` : `${formatParams}`}`
      this.$store.dispatch('accounts/DOWNLOAD', {
        resource: 'download-center/export-purchases-from-sii',
        payload: {
          query: formatQuery ?? '',
          extension: this.types.filter(item => item !== 'XML').toString()
        },
        loader: false
      })
      .catch((error) => {
        this.errorCSV = this.parseErrors(error.response.data)
      })
      .finally(() => {
        this.$store.commit('global/SET_API_SERVICES', false)
        this.types = []
      })
    },
   deleteQueries () {
    let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
    if (currentQuery.ordering) delete currentQuery.ordering
    if (currentQuery.name) delete currentQuery.name
    return currentQuery
  }
 }
}
</script>